import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { GetTerminalRequest, RegisteredTerminal } from './types';

export const useTerminalMutation = (
  options?: Omit<UseMutationOptions<RegisteredTerminal, ApiError, GetTerminalRequest>, 'mutationFn'>
) => {
  const { authenticationPassPhrase } = useOnSiteInfo();
  const {
    gatewayClickCollect: { terminalsApi },
  } = useTasterApis();

  return useMutation({
    ...options,
    mutationFn: (requestParams) => {
      return terminalsApi.getTerminal(requestParams, async ({ init }) => ({
        ...init,
        headers: {
          ...init.headers,
          Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`,
        },
      }));
    },
  });
};
