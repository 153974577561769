import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { ResponseError } from '@tasterkitchens/client-ts-gateway-click-collect-v1';
import camelize from 'camelize-ts';

import ApiError from '@/api/ApiError';
import { useTasterApis } from '@/api/TasterApiContext';
import { useOnSiteInfo } from '@/hooks/useOnSiteInfo';

import { CancelActionRequest } from './types';

export const useCancelActionMutation = (
  options?: Omit<UseMutationOptions<void, ApiError, CancelActionRequest>, 'mutationFn'>
) => {
  const { authenticationPassPhrase } = useOnSiteInfo();
  const {
    gatewayClickCollect: { terminalsApi },
  } = useTasterApis();

  return useMutation({
    ...options,
    mutationFn: async (requestParams) => {
      try {
        return await terminalsApi.cancelAction(requestParams, async ({ init }) => ({
          ...init,
          headers: {
            ...init.headers,
            Authorization: `Basic ${window.btoa(authenticationPassPhrase)}`,
          },
        }));
      } catch (error) {
        if (error instanceof ResponseError) {
          const errorResponse = (await error.response.json()) as ApiError;
          throw new ApiError(camelize(errorResponse));
        }

        throw error;
      }
    },
  });
};
